// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;

		${breakup.large`
			${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
		`}
	`
);
