// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query ContactOptions {
				contact: datoCmsOptionsPage {
					phone
					email
					times: openingTimes
					address {
						line: addressLine
					}
				}
			}
		`
	);
	return data.contact;
};
