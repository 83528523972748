// Imports
// ------
import React, { useState } from 'react';
import Field from './Field';
import axios from 'axios';

// Styles
// ------
import { Jacket, Sweater, Button, Success } from './styles';

// Component
// ------
function Form() {
	// NOTE • States
	const [formData, setFormData] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [emailTest, setEmailTest] = useState('');
	const [mailStatus, setMailStatus] = useState('');

	// NOTE • Handlers
	const handleChange = (e) => {
		const validEmailRegex = RegExp(
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		);

		if (e.target.type === 'email') {
			setEmailTest({ value: e.target.value });
			const test = validEmailRegex.test(emailTest.value);
			setEmailError(!test);
		}

		setTimeout(() => {
			if (e.target.matches(':-internal-autofill-selected')) {
				setEmailTest({ value: e.target.value });
				const test = validEmailRegex.test(emailTest.value);
				setEmailError(!test);
			}

			clearTimeout();
		}, 250);

		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		function encode(data) {
			return Object.keys(data)
				.map(
					(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
				)
				.join('&');
		}

		const axiosOptions = {
			url: window.location.href,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: encode({ 'form-name': e.target.getAttribute('name'), ...formData }),
		};

		axios(axiosOptions)
			.then((response) => {
				setMailStatus(true);
			})
			.catch((err) => alert(err));
	};

	// NOTE • Form Props
	const formProps = {
		name: 'Contact Balbycare',
		method: 'POST',
		onSubmit: handleSubmit,
	};

	const closeStatus = () => {
		setMailStatus(!mailStatus);
	};

	return (
		<>
			<Jacket>
				<Sweater
					data-netlify='true'
					netlify-honeypot='bot-field'
					autocomplete='off'
					{...formProps}>
					<input type='hidden' name='bot-field' />
					<input type='hidden' name='form-name' value='Contact Balbycare' />

					<Field
						textarea
						name='Message'
						label='How can we help?'
						placeholder='Start your message'
						onChange={handleChange}
					/>

					<Field
						input
						name='Full Name'
						label='What’s your name?'
						placeholder='John Doe'
						onChange={handleChange}
					/>

					<Field
						input
						emailError={emailError}
						type='email'
						name='Email Address'
						label='What’s your email?'
						placeholder='john.doe@email.com'
						onChange={handleChange}
					/>

					<Field
						input
						name='Full Name'
						label='What’s your number?'
						placeholder='e.g. 01302 709907'
						onChange={handleChange}
					/>

					<Button type='submit' className='cursor-hover'>
						<span data-text='Send message'>Send message</span>
					</Button>
				</Sweater>
			</Jacket>

			{mailStatus && (
				<Success>
					Your request has been received. We will contact you shortly.
					<button onClick={closeStatus}>Close</button>
				</Success>
			)}
		</>
	);
}

export default Form;
