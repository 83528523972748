// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, P } from '@tackl';
import { h5Styles } from '@type';

// Exports
// ------
export const Jacket = styled.aside(
	(props) => css`
		position: relative;
		z-index: 2;
		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: flex-start;
		gap: clamp(3.6rem, 8vh, 6rem);
		padding: ${props.theme.padS} 0 0 0;

		${breakup.large`
			background: transparent;
			padding: 0;
		`}

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: -2.4rem;
			right: -2.4rem;
			bottom: 0;
			${props.theme.grad(180, props.theme.bc4, `${props.theme.bc4}00`)}

			${breakup.large` display: none; `}
		}
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;
		z-index: 2;
	`
);

export const Label = styled(P)(
	(props) => css`
		opacity: ${props.secondary ? 0.6 : 1};
	`
);

export const GradLink = styled.a(
	(props) => css`
		${h5Styles}
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

		position: relative;
		display: inline-block;
		padding: 1.2rem 0;

		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: ${props.theme.ease};

		&:hover {
			${breakup.large`
				&:after {
					background-position: -200%;
				}
			`}
		}

		&:after {
			${breakup.large`
				content: attr(data-text);
				position: absolute;
				z-index: 12;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: linear-gradient(
					to right,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 1) 50%,
					rgba(255, 255, 255, 0) 100%
				);
				background-size: 200% 100%;
				background-position: 200%;
				background-repeat: no-repeat;

				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: background-position 0.75s ease-in-out;
			`}
		}
	`
);

export const Address = styled.ul(
	(props) => css`
		${h5Styles}
		opacity: 0.6;
		margin-top: 1.2rem;
	`
);
