// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query ContactHero {
				contact: datoCmsContactPage {
					title: heroTitle
					text: heroExcerpt
				}
			}
		`
	);
	return data.contact;
};
