// Imports
// ------
import React, { useRef, useEffect, useState } from 'react';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { loaderState } from '@states/loader';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import { Jacket, Content, Title, Text } from './styles';

// Component
// ------
function Hero() {
	// NOTE • Data
	const { title, text } = useDato();

	// NOTE • Refs
	const titleRef = useRef();

	// NOTE • States
	const [titleAni, setTitleAni] = useState(false);

	// NOTE • Split Title Characters
	useEffect(() => {
		let Splitting;
		if (typeof document !== 'undefined') {
			Splitting = require('splitting');

			Splitting({
				target: titleRef.current,
				by: 'chars',
			});
		}
	}, []);

	// NOTE • Check for loader status
	useEffect(() => {
		if (loaderState.isNeeded === false) {
			setTitleAni(true);
		}
	}, [loaderState.isNeeded]);

	return (
		<Jacket>
			<Row isExpanded isEqual>
				<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
					<Content>
						<Title ref={titleRef} isAnimating={titleAni}>
							{title}
						</Title>
					</Content>
				</Column>

				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					pushOnLarge={2}
					large={4}
					mpad>
					<Content>
						<Text>{text}</Text>
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
}

export default observer(Hero);
