// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Footer() {
	// NOTE • Year
	const date = new Date();
	const year = date.getFullYear();

	return (
		<Jacket>
			<span>&copy; Balbycare {year}</span>
		</Jacket>
	);
}

export default Footer;
