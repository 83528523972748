// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { pStyles, h4Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		padding: ${props.theme.padS} 0;

		${breakup.large`
			padding: 0;
			margin: 0;
		`}

		&:before {
			${props.theme.grad(0, props.theme.bc3, `${props.theme.bc3}00`)}
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: -2.4rem;
			right: -2.4rem;
			bottom: 0;

			${breakup.large` display: none; `}
		}
	`
);

export const Sweater = styled.form(
	(props) => css`
		position: relative;
		z-index: 2;

		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		flex: 1;
		gap: 3.6rem;

		${breakup.large`
			gap: 6rem;
		`}
	`
);

export const Button = styled.button(
	(props) => css`
		${h4Styles}
		position: relative;
		z-index: 2;
		display: inline-block;
		padding: 0;
		background: transparent;

		span {
			position: relative;
			display: inline-block;
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			cursor: pointer;
			transition: ${props.theme.ease};

			&:after {
				${breakup.large`
					content: attr(data-text);
					position: absolute;
					z-index: 12;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: linear-gradient(
						to right,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 50%,
						rgba(255, 255, 255, 0) 100%
					);
					background-size: 200% 100%;
					background-position: 200%;
					background-repeat: no-repeat;

					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					transition: background-position 0.75s ease-in-out;
				`}
			}
		}

		&:hover {
			${breakup.large`
				span:after {
					background-position: -200%;
				}
			`}
		}
	`
);

export const Success = styled.div(
	(props) => css`
		${pStyles}
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
		position: fixed;
		z-index: 120;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: space-between;

		color: ${props.theme.white};
		padding: 0.6rem 2.4rem;
		text-align: center;

		button {
			${pStyles}
			display: inline-block;
			background: transparent;
			position: relative;
			padding: 0;
			margin-left: 0.6rem;
			cursor: pointer;
			opacity: 0.6;
			transition: ${props.theme.ease};

			${breakup.large`
                &:hover { opacity: 1;  }
            `}
		}
	`
);
