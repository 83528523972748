// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { h5Styles, pStyles } from '@type';

// Exports
// -------
export const Label = styled.label(
	(props) => css`
		${h5Styles}
		display: block;
		color: ${props.theme.white};
		transform-origin: bottom left;
		transition: all 1s ${props.theme.bezzy};
	`
);

const sharedFields = (props) => css`
	${pStyles}

	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	border: none;
	background: transparent;
	padding: 2.4rem 0;
	color: ${props.theme.white};

	&:focus,
	&:active {
		outline: none;
	}

	&::placeholder {
		${pStyles}
		color: ${props.theme.white}60;
	}
`;

export const Input = styled.input(
	(props) => css`
		${sharedFields}
		height: 7.7rem;
	`
);

export const TextArea = styled.textarea(
	(props) => css`
		${sharedFields}
		resize: none;
		overflow: hidden;
		grid-area: 1 / 1 / 2 / 2;
		height: 7.7rem;
	`
);

export const TextAreaWrap = styled.div(
	(props) => css`
		${sharedFields}
		padding: 0;
		display: grid;

		&:after {
			padding: 1.2rem 0 2.4rem 0;
			content: attr(data-replicated-value);
			white-space: pre-wrap;
			visibility: hidden;
			grid-area: 1 / 1 / 2 / 2;
		}
	`
);

export const ErrorMes = styled.span(
	(props) => css`
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		height: 2px;
		background: ${props.theme.neg};

		transform-origin: center left;
		transform: scaleX(${props.focussing ? 0 : 1});

		transition: all 1s ${props.theme.bezzy};
	`
);

export const Jacket = styled.div(
	(props) => css`
		position: relative;
		width: 100%;
		padding: ${props.focussing ? `0 2.4rem` : `0`};
		transition: all 1s ${props.theme.bezzy};

		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 2px;
			height: ${props.focussing ? `100%` : `2px`};
			background: ${props.focussing
				? `${props.theme.white}10`
				: `${props.theme.white}20`};

			transform-origin: bottom center;
			transition: all 1s ${props.theme.bezzy};
		}

		${Label} {
			font-weight: ${props.focussing ? props.theme.semi : props.theme.reg};
			transform: scale(${props.focussing ? 0.5 : 1});
		}
	`
);
