// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import PageTransition from '@parts/PageTransition';
import Contact from '@parts/Contact';
import { graphql } from 'gatsby';

// Component
// ------
function ContactPage({ transitionStatus, entry, exit, data }) {
	// NOTE • Props
	const transProps = {
		status: transitionStatus,
		entry: entry,
		exit: exit,
	};

	return (
		<>
			<Seo content={data.page.seo} />

			<PageTransition {...transProps}>
				<Contact />
			</PageTransition>
		</>
	);
}

export default ContactPage;

// GraphQL
// ------
export const query = graphql`
	query Contact {
		page: datoCmsContactPage {
			seo: seoMeta {
				title
				desc: description
				card: twitterCard
				image {
					url
				}
			}
		}
	}
`;
