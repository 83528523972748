// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 3;
		padding: 0 0 ${props.theme.padS} 2.4rem;
		pointer-events: none;
		user-select: none;

		${breakup.medium`
			padding: 0 0 6rem 3.6rem;
		`}

		${breakup.large`
			padding: 0 0 6rem 6rem;
			background: ${props.theme.bc4};
		`}

		span {
			${pStyles}
			display: block;
			opacity: 0.4;
		}
	`
);
