// Imports
// ------
import React from 'react';
import Form from './Form';
import Details from './Details';
import { Row, Column } from '@waffl';
import { useDato } from './data';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Options() {
	// NOTE • Data
	const { email, phone, times, address } = useDato();

	// NOTE • Props
	const formProps = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		large: 5,
		mpad: true,
	};

	const detailsProps = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		pushOnLarge: 2,
		large: 4,
		mpad: true,
	};

	// NOTE • Passed Data
	const passedData = {
		email: email,
		phone: phone,
		times: times,
		address: address,
	};

	return (
		<Jacket padBottom>
			<Row isExpanded>
				<Column {...formProps}>
					<Form />
				</Column>

				<Column {...detailsProps}>
					<Details {...passedData} />
				</Column>
			</Row>
		</Jacket>
	);
}

export default Options;
