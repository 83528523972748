// Imports
// -------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query Social {
				social: datoCmsOptionsPage {
					accounts: socialAccounts {
						name
						url
					}
				}
			}
		`
	);
	return data.social;
};
