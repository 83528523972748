// Imports
// ------
import React from 'react';
import Social from '@parts/Social';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Content, Label, GradLink, Address } from './styles';

// Component
// ------
function Details({ phone, email, address, times }) {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	return (
		<Jacket>
			{bp.large ? null : (
				<Content>
					<Social />
				</Content>
			)}

			<Content>
				<Label>Call us</Label>
				<GradLink
					data-text={phone}
					href={`tel:${phone}`}
					className='cursor-hover'>
					{phone}
				</GradLink>
				<Label secondary>Mon - Fri ({times})</Label>
			</Content>

			<Content>
				<Label>Email us</Label>
				<GradLink
					data-text='Click here'
					href={`mailto:${email}`}
					className='cursor-hover'>
					Click here
				</GradLink>
				<Label secondary>{email}</Label>
			</Content>

			<Content>
				<Label>Address</Label>
				<Address>
					{address.map(({ line }, i) => (
						<li key={i}>{line}</li>
					))}
				</Address>
			</Content>

			{bp.large && (
				<Content>
					<Social />
				</Content>
			)}
		</Jacket>
	);
}

export default Details;
