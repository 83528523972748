// Imports
// ------
import React from 'react';
import Hero from './Hero';
import Options from './Options';
import Footer from './Footer';

// Component
// ------
function Contact() {
	return (
		<>
			<Hero />
			<Options />
			<Footer />
		</>
	);
}

export default Contact;
