// Imports
// ------
import React, { useState, useRef } from 'react';

// Styles
// ------
import {
	Jacket,
	Label,
	Input,
	TextArea,
	TextAreaWrap,
	ErrorMes,
} from './styles';

// Component
// ------
function Field({
	label,
	input,
	type,
	emailError,
	textarea,
	onClick,
	onChange,
	placeholder,
	name,
	id,
	className,
}) {
	// NOTE • Refs
	const textareaWrap = useRef();

	// NOTE • States
	const [focus, setFocus] = useState(false);

	// NOTE • Handlers
	const handleFocus = () => {
		setFocus(true);
	};

	const handleBlur = () => {
		setFocus(false);
	};

	const handleInput = (e) => {
		textareaWrap.current.dataset.replicatedValue = e.target.value;
	};

	// NOTE • If field type is an <input />
	if (input)
		return (
			<Jacket focussing={focus} className={className}>
				<Label>{label}</Label>
				<Input
					required
					type={type}
					onClick={onClick}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder={placeholder}
					name={name}
					id={id}
					onChange={onChange}
				/>

				{emailError && <ErrorMes focussing={focus} />}
			</Jacket>
		);

	// NOTE • If field type is a <textarea />
	if (textarea)
		return (
			<Jacket focussing={focus} className={className}>
				<Label>{label}</Label>
				<TextAreaWrap data-replicated-value='' ref={textareaWrap}>
					<TextArea
						required
						onClick={onClick}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onInput={handleInput}
						placeholder={placeholder}
						name={name}
						id={id}
						rows='2'
						onChange={onChange}
					/>
				</TextAreaWrap>
			</Jacket>
		);
}

export default React.memo(Field);
