// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`
);

export const Link = styled.a(
	(props) => css`
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 1.8rem;
		border-radius: 100%;

		width: 6rem;
		height: 6rem;
		margin-right: 1.2rem;

		${breakup.smedium` margin-right: 2.4rem; `}

		&:last-child {
			margin: 0;
		}

		&:hover {
			${breakup.large`
				svg {
					fill: ${props.theme.bc4};
				}

				&:before {
					transform: translate(-50%, -50%) scale(1);
					opacity: 1;
				}

				&:after {
					width: 150%;
					height: 150%;
					opacity: 0;
				}
			`}
		}

		svg {
			position: relative;
			z-index: 1;
			transition: all 0.5s ${props.theme.bezzy};
		}

		&:before {
			${breakup.large`
				content: '';
				position: absolute;
				z-index: 0;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.25);

				width: calc(100% + 4px);
				height: calc(100% + 4px);
				border-radius: inherit;
				background: ${props.theme.white};
				opacity: 0;

				transition: all 0.55s ${props.theme.bezzy};
			`}
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);

			width: 100%;
			height: 100%;
			border-radius: inherit;
			border: 2px solid ${props.theme.white};
			opacity: 0.2;

			transition: all 0.55s ${props.theme.bezzy};
		}
	`
);
