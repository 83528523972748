// Imports
// ------
import React from 'react';
import Icon from '@icons';
import { useDato } from './data';

// Styles
// ------
import { Jacket, Link } from './styles';

// Component
// ------
function Social() {
	//  TODO • Data
	const { accounts } = useDato();

	// NOTE • External Link Props
	const extProps = {
		target: '_blank',
		rel: 'noopener noreferrer',
		className: 'cursor-hover',
	};

	return (
		<Jacket>
			{accounts.map(({ name, url }) => (
				<Link href={url} {...extProps} key={name}>
					<Icon type={name.toLowerCase()} />
				</Link>
			))}
		</Jacket>
	);
}

export default Social;
